import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Tappy Miner Data Usage and Private Policy.
      </header>
      <main>
        <div className="sub-section">
          <div className="sub-header">
            How we use your data. 
          </div>
          <p>
            We here at tappy miner miners limited (not a real company, although these policies apply to tappy miner the game(avaiable on google play)) will not be collecting nor using your data in any sense. This means all data regarding the game (this is only hi score data) will be processed by googles play services and keep on google cloud servers.
          </p>
        </div>
        <div className="sub-section">
          <div className="sub-header">
            Private Policy
          </div>
          <p>
            At TappyMinerDataAndPrivatePolicy, accessible from tappyminer.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by TappyMinerDataAndPrivatePolicy and how   we use it.
            <br/>
            <br/>
            If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us. Our Privacy Policy was generated with the help of GDPR Privacy Policy Generator from GDPRPrivacyNotice.com
            <br/>
            <br/>
            General Data Protection Regulation (GDPR)
            We are a Data Controller of your information.
            <br/>
            <br/>
            Tappy Miner Miners legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Information we collect and the specific context in which we collect the information:
            <br/>
            <br/>
            Tappy Miner Miners needs to perform a contract with you
            You have given Tappy Miner Miners permission to do so
            Processing your personal information is in Tappy Miner Miners legitimate interests
            Tappy Miner Miners needs to comply with the law
            Tappy Miner Miners will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
            <br/>
            <br/>
            If you are a resident of the European Economic Area (EEA), you have certain data protection rights. If you wish to be informed what Personal Information we hold about you and if you want it to be removed from our systems, please contact us.
            <br/>
            <br/>
            In certain circumstances, you have the following data protection rights:
            <br/>
            <br/>
            The right to access, update or to delete the information we have on you.
            The right of rectification.
            The right to object.
            The right of restriction.
            The right to data portability
            The right to withdraw consent
            Log Files
            TappyMinerDataAndPrivatePolicy follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
            <br/>
            <br/>
            Google DoubleClick DART Cookie
            Google is one of a third-party vendor on our site. It also uses cookies, known as DART cookies, to serve ads to our site visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads
            <br/>
            <br/>
            Privacy Policies
            You may consult this list to find the Privacy Policy for each of the advertising partners of TappyMinerDataAndPrivatePolicy.
            <br/>
            <br/>
            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on TappyMinerDataAndPrivatePolicy, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
            <br/>
            <br/>
            Note that TappyMinerDataAndPrivatePolicy has no access to or control over these cookies that are used by third-party advertisers.
            <br/>
            <br/>
            Third Party Privacy Policies
            TappyMinerDataAndPrivatePolicy's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
            <br/>
            <br/>
            You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.
            <br/>
            <br/>
            Children's Information
            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
            <br/>
            <br/>
            TappyMinerDataAndPrivatePolicy does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
            <br/>
            <br/>
            Online Privacy Policy Only
            Our Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in TappyMinerDataAndPrivatePolicy. This policy is not applicable to any information collected offline or via channels other than this website.
            <br/>
            <br/>
            Consent
            By using our website, you hereby consent to our Privacy Policy and agree to its terms.
            <br/>
            <br/>
            This all stands, although please bear in mind i am but one, and any amendment to your data may take a time due to them being store on google systems. The best way to combat any use of your data in leaderboards or any other such system is to remove your data via the play services located in google play. thank your :)
          </p>
        </div>
      </main>
    </div>
  );
}

export default App;
